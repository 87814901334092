import React from 'react';
import {useNavigate} from "react-router-dom";
import {latestStoriesClasses, StyledLatestStories} from "./StyledLatestStories";
import {Button, Card, CardActionArea, CardContent, CardMedia, Container, Grid, Typography} from "@mui/material";
import {primary} from "../../common/theme";
import location from "../../assets/location.png";
import { latestStories } from "../../Stories/stories-items";
import { NavigationRoutes } from "../../routes";

export const LatestStories = () => {
    const navigate = useNavigate();

    return <StyledLatestStories className={latestStoriesClasses.root}>
        <Container maxWidth={"lg"}>
            <Grid container justifyContent={"center"} sx={{position: "relative"}}>
                <Typography variant={"h1"} component="div" sx={{color: primary, margin: "30px 0", zIndex: 100}}>
                    Latest SRHR-UHC Implementation Stories
                </Typography>
            </Grid>
            <Grid container sx={{margin: "50px 0 150px 0"}}>
                { latestStories.slice().reverse().map((story)=>(
                    <Grid item lg={4} key={story.id}>
                        <Card sx={{ maxWidth: 350 }} className={latestStoriesClasses.card}>
                            <CardActionArea sx={{position: "relative"}}>
                                <CardMedia
                                    component="img"
                                    className={latestStoriesClasses.cardImg}
                                    image={story.image}
                                    alt={story.imagealt}
                                />
                                <Grid container className={latestStoriesClasses.cardLocation}>
                                    <img src={location} alt={"location"}/>
                                    <Typography variant={"h6"} sx={{color: "white",
                                        textShadow: "3px 3px 5px rgba(0,0,0,0.5)",
                                    }}>{story.country}</Typography>
                                </Grid>

                                <CardContent className={latestStoriesClasses.cardContent}>
                                    <Typography gutterBottom variant="h6" sx={{height: "70px"}}>
                                        {story.header}
                                    </Typography>
                                    <Button
                                        variant={"contained"}
                                        onClick={()=>{
                                            navigate(`/${NavigationRoutes.Story}/${story.id}`);
                                            window.scrollTo(0, 0);
                                        }}
                                        className={latestStoriesClasses.button}
                                    >
                                        Read Full Story
                                    </Button>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Container>
    </StyledLatestStories>;
};
