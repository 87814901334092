import {
  tabExpertReviewClasses,
  StyledTabExpertReview,
} from "./StyledTabExpertReview";
import { Avatar, Grid, CardContent, Typography, Card } from "@mui/material";
import { primary } from "../../../common/theme";

import michelle from "./images/Dr Michelle Pic.jpg";
import shannon from "./images/Shannon-Kowalski.jpg";
import rodolfo from "./images/Dr Rodolfo Gomez.jpg";
import witter from "./images/Professor-Sophie-Witter.png";
import mikaela from "./images/Mikaela-Hildebrand.jpg";
import hilber from "./images/Dr-Adriane-Martin-Hilber.jpg";
import jyoti from "./images/Jyoti Tewari.jfif.jpg";
import suchi from "./images/DR-Suchi-Gaur.jpg";

const members: { name: string; image: string; position: string }[] = [
  {
    name: "Dr. Michelle Remme",
    image: michelle,
    position: "Global Fund to Fight AIDS, Tuberculosis and Malaria",
  },
  {
    name: "Ms. Shannon Kowalski",
    image: shannon,
    position: "Independent SRHR & Gender Expert",
  },
  {
    name: "Dr. Rodolfo Gomez Ponce de Leon",
    image: rodolfo,
    position: "Pan American Health Organization (PAHO)",
  },
  {
    name: "Professor Sophie Witter",
    image: witter,
    position: "Queen Margaret University",
  },
  {
    name: "Ms. Mikaela Hildebrand",
    image: mikaela,
    position: "Swedish International Development Cooperation Agency",
  },
  {
    name: "Dr Adriane Martin Hilber",
    image: hilber,
    position: "Swiss Tropical Health Institute",
  },
  {
    name: "Mr. Jyoti Tewari",
    image: jyoti,
    position: "UNFPA (Eastern and Southern Africa)",
  },
  {
    name: "Dr. Suchi Gaur",
    image: suchi,
    position: "Young Women's Christian Association ",
  },
];

export const TabExpertReview = () => {
  return (
    <StyledTabExpertReview className={tabExpertReviewClasses.root}>
      <Typography
      >
        A multi-stakeholder, cross-regional Expert Review Group (ERG) provides strategic guidance for the development of the Sexual and Reproductive Health and Universal Health Coverage Learning by Sharing Portal (SRHR-UHC LSP). Members represent relevant United Nations agencies, civil society organizations, experts, and academia.
      </Typography>
      <Grid
        container
        spacing={6}
        columnSpacing={1}
        sx={{ marginTop: "15px", marginBottom: "60px" }}
      >
        {members.map((member, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card
              sx={{ }}
              className={tabExpertReviewClasses.card}
            >
              <Avatar
                sx={{ height: "186px", width: "186px", marginBottom: "0!important" }}
                src={member.image}
              />
              <CardContent sx={{ color: primary, paddingBottom: "0!important" }}>
                <Typography variant="h2" sx={{ fontSize: "18px", fontFamily:"Inter", marginBottom: "0", fontWeight: "bold", lineHeight: "24px" }}>
                  {member.name}
                </Typography>
                <p style={{ fontSize: "18px", fontFamily:"Inter", lineHeight: "24px", marginTop:"5px", color: "#2C4560" }}>
                  {member.position}
                </p>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </StyledTabExpertReview>
  );
};
