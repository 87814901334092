import {Grid, styled} from "@mui/material";

const VIEW_CONTAINER_PREFIX = "ViewContainer";

export const viewContainerClasses = {
    root: `${VIEW_CONTAINER_PREFIX}-root`,
};

export const StyledViewContainer = styled(Grid)(({ theme }) => ({
    [`&.${viewContainerClasses.root}`]: {},
}));

