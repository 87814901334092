import React, { useState } from "react";
import { loginClasses as classes, StyledLogin } from "./StyledLogin";
import { Button, FormControl, Grid, Input, InputLabel } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { NavigationRoutes } from "../routes";
import { useGA } from "../common/hooks";

export const Login = () => {
    useGA("Login");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();

    const login = ()=> {
        if(username === "admin" && password === "dasLEQZ9dGSJTvVU") {
            localStorage.setItem("token", "token-set");
            navigate(`/${NavigationRoutes.Admin}`)
        }
    }

    return <StyledLogin className={classes.root}>
        <Grid container justifyContent={"center"} flexDirection={"column"} alignItems={"center"} sx={{height: "800px"}}>
            <FormControl sx={{height: "80px", width: "250px"}}>
                <InputLabel htmlFor="username">Username</InputLabel>
                <Input id="username" value={username} onChange={(event)=>setUsername(event.target.value)}/>
            </FormControl>
            <FormControl sx={{height: "80px", width: "250px"}}>
                <InputLabel htmlFor="password">Password</InputLabel>
                <Input id="password" type="password" value={password} onChange={(event)=>setPassword(event.target.value)} />
            </FormControl>
            <Button variant="outlined" onClick={()=>login()}>Login</Button>
        </Grid>
    </StyledLogin>;
};
