import React from 'react';
import {headerClasses, StyledHeader} from "./StyledHeader";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import {Grid} from "@mui/material";
import {customNavigate, links, pages} from "../routes";
import {useNavigate} from "react-router-dom";
import {Mode} from "../interfaces/mode";
import whoBlue from "./icons/who-blue.png";
import hrpBlue from "./icons/hrp-blue.png";
import unfpaColor from "./icons/unfpa-color.png";
import whoWhite from "./icons/who-white.png";
// import hrpWhite from "./icons/hrp-white.png";
// import hrpWhiteSvg from "./icons/hrp-white.svg";
import hrpWhiteSvg from "./icons/hrp-white-2.svg";
import unfpaWhite from "./icons/unfpa-white.png";

interface HeaderProps {
    mode: Mode;
}

export const Header = ({ mode }: HeaderProps) => {
    let navigate = useNavigate();

    const toggleMenu = () => {
        let menuWrap = document.querySelector(".menuDrop");
        if(menuWrap) {
            menuWrap.classList.toggle("menuDropOpen");
        }
    };

    if(mode === Mode.Light) {
        return <StyledHeader className={headerClasses.root}>
            <AppBar position="sticky" className={headerClasses.appBarLight}>
                <Container maxWidth={"lg"} className={headerClasses.container}>
                    <Toolbar disableGutters component={Grid}>
                        <Grid container sx={{width: "60%"}}>
                            <a href="https://www.who.int/" target="_blank" rel="noreferrer">
                                <img className={headerClasses.logo} src={whoBlue} width={138} height={43} alt={"Who Blue"}/>
                            </a>
                            <a href="https://www.who.int/teams/sexual-and-reproductive-health-and-research-(srh)/human-reproduction-programme" target="_blank" rel="noreferrer">
                                <img className={headerClasses.logo} src={hrpBlue} width={99} height={44} alt={"Hrp Blue"}/>
                            </a>
                            <a href="https://www.unfpa.org/" target="_blank" rel="noreferrer">
                                <img className={headerClasses.logo} src={unfpaColor} width={92} height={43} alt={"Unfpa Light"}/>
                            </a>
                        </Grid>

                        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, width: "100%" }}>
                            {pages.map((page, index) => (
                                <Button
                                    key={page}
                                    className={headerClasses.navigation}
                                    onClick={()=> {
                                        customNavigate(navigate, links[index]);
                                    }}
                                    sx={{ my: 2, color: 'white', display: 'block', textTransform: "capitalize" }}
                                >
                                    {page}
                                </Button>
                            ))}
                        </Box>

                        <Box sx={{ flexGrow: 1, display: { md: 'none' } }} className="menuWrap">
                            
                            <Button
                                className="menuButton"
                                onClick={()=> {
                                    toggleMenu();
                                }}
                                sx={{ my: 2, color: 'white', display: 'block', textTransform: "capitalize" }}
                            >
                               My Menu
                            </Button>

                            <Box sx={{ flexGrow: 1 }} className="menuDrop">
                                {pages.map((page, index) => (
                                    <Button
                                        key={page}
                                        className={headerClasses.navigation}
                                        onClick={()=> {
                                            customNavigate(navigate, links[index]);
                                        }}
                                        sx={{ my: 2, color: 'white', display: 'block', textTransform: "capitalize" }}
                                    >
                                        {page}
                                    </Button>
                                ))}
                            </Box>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
        </StyledHeader>;
    } else if(mode === Mode.Dark) {
        return <StyledHeader className={headerClasses.root}>
            <AppBar position="sticky" className={headerClasses.appBarDark}>
                <Container maxWidth={"lg"} className={headerClasses.container}>
                    <Toolbar disableGutters component={Grid}>
                        <Grid container sx={{width: { xs: '100%', lg: '60%' }}} className="logosDesktop">
                            <a href="https://www.who.int/" target="_blank" rel="noreferrer">
                                <img className={headerClasses.logo} src={whoWhite} width={138} height={43} alt={"Who Blue"}/>
                            </a>
                            <a href="https://www.who.int/teams/sexual-and-reproductive-health-and-research-(srh)/human-reproduction-programme" target="_blank" rel="noreferrer">
                            <img className={headerClasses.logo} src={hrpWhiteSvg} width={140} height={44} alt={"Hrp Blue"}/>
                            </a>
                            <a href="https://www.unfpa.org/" target="_blank" rel="noreferrer">
                            <img className={headerClasses.logo} src={unfpaWhite} width={92} height={43} alt={"Unfpa Light"}/>
                            </a>
                        </Grid>
                        
                        <Grid container sx={{width: { xs: '100%', lg: '60%' }}} className="logosMobile">
                            <a href="https://www.who.int/" target="_blank" rel="noreferrer">
                                <img className={headerClasses.logo} src={whoWhite} width={85} height={31} alt={"Who Blue"}/>
                            </a>
                            <a href="https://www.who.int/teams/sexual-and-reproductive-health-and-research-(srh)/human-reproduction-programme" target="_blank" rel="noreferrer">
                            <img className={headerClasses.logo} src={hrpWhiteSvg} width={90} height={30} alt={"Hrp Blue"}/>
                            </a>
                            <a href="https://www.unfpa.org/" target="_blank" rel="noreferrer">
                            <img className={headerClasses.logo} src={unfpaWhite} width={63} height={28} alt={"Unfpa Light"}/>
                            </a>
                        </Grid>

                        <Box sx={{ flexGrow: 1, display: { xs: 'none', lg: 'flex' }, width: "100%" }}>
                            {pages.map((page, index) => (
                                <Button
                                    key={page}
                                    className={headerClasses.navigation}
                                    onClick={()=> {
                                        customNavigate(navigate, links[index]);
                                    }}
                                    sx={{ my: 2, color: 'white', display: 'block', textTransform: "capitalize" }}
                                >
                                    {page}
                                </Button>
                            ))}
                        </Box>


                        <Box sx={{ flexGrow: 1, display: { lg: 'none' } }}>
                            
                            <Button
                                className="menuButton"
                                onClick={()=> {
                                    toggleMenu();
                                }}
                                sx={{ my: 2, display: 'block', textTransform: "uppercase" }}
                            >
                               Menu
                            </Button>

                        </Box>
                    </Toolbar>
                    <Box sx={{ flexGrow: 1, display: { lg: 'none' } }} className="menuDrop">
                        {pages.map((page, index) => (
                            <Button
                                key={page}
                                className={headerClasses.navigation}
                                onClick={()=> {
                                    customNavigate(navigate, links[index]);
                                }}
                                sx={{ my: 2, color: 'white', display: 'block', width: '100%', textTransform: "capitalize" }}
                            >
                                {page}
                            </Button>
                        ))}
                    </Box>
                </Container>
            </AppBar>
        </StyledHeader>;
    }
    return null;
};
