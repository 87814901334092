import React from 'react';
import {homeClasses, StyledHome} from "./StyledHome";
import {ViewContainer} from "../ViewContainer/ViewContainer";
import {Caption} from "./Caption/Caption";
import {FeatureStory} from "./FeatureStory/FeatureStory";
import {LatestStories} from "./LatestStories/LatestStories";
import {Mode} from "../interfaces/mode";
import { Quotes } from "./Quotes/Quotes";
import { useGA } from "../common/hooks";

let key = 1;
export const Home = () => {
    key++;
    useGA("Home");
    return <ViewContainer mode={Mode.Dark}>
        <StyledHome className={homeClasses.root}>
            <Caption/>
            <Quotes />
            <FeatureStory key={key}/>
            <LatestStories/>
            {/*<AboutSection/>*/}
            {/*<UsefulLinksSection />*/}
        </StyledHome>
    </ViewContainer>;
};
