import { StyledAboutTabs } from "./StyledAboutTabs";
import { TabPanel } from "@mui/lab";
import { TabAbout } from "./TabAbout/TabAbout";
import { TabApproach } from "./TabApproach/TabApproach";
import { TabExpertReview } from "./TabExpertReview/TabExpertReview";

export const AboutTabs = () => {
  return (
    <StyledAboutTabs>
      <TabPanel value="1" className="tabStyles" >
        <TabAbout />
      </TabPanel>
      <TabPanel value="2" className="tabStyles" >
        <TabApproach />
      </TabPanel>
      <TabPanel value="3" className="tabStyles" >
        <TabExpertReview />
      </TabPanel>
    </StyledAboutTabs>
  );
};
