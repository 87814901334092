import {Grid, styled} from "@mui/material";
import { primary } from "../../common/theme";

const STORIES_LIST_PREFIX = "StoriesList";

export const storiesListClasses = {
    root: `${STORIES_LIST_PREFIX}-root`,
    card: `${STORIES_LIST_PREFIX}-card`,
    cardLocation: `${STORIES_LIST_PREFIX}-cardLocation`,
    cardImg: `${STORIES_LIST_PREFIX}-cardImg`,
    cardContent: `${STORIES_LIST_PREFIX}-cardContent`,
    button: `${STORIES_LIST_PREFIX}-button`,
    keywords: `${STORIES_LIST_PREFIX}-keywords`,
    keywordtag: `${STORIES_LIST_PREFIX}-keywordTag`,
};

export const StyledStoriesList = styled(Grid)(({ theme }) => ({
    [`&.${storiesListClasses.root}`]: {
        padding: "70px 0 100px 0"
    },
    [`& .${storiesListClasses.card}`]: {
        background: "transparent",
        color: "#fff",
        boxShadow: "none",
        position: "relative",
        cursor: "auto",
    },
    [`& .${storiesListClasses.cardLocation}`]: {
        position: "absolute",
        padding: "10px",
        alignItems: "center",
        [`& img`]: {
            marginRight: "15px"
        },
        [`& h6`]: {
            fontWeight: "normal"
        },
        width: "45%",
        top: 5,
        left: 5,
        background: `${primary}8F`,
        borderRadius: "5px",
        justifyContent: "space-around"
    },
    [`& .${storiesListClasses.cardImg}`]: {
        height: "250px",
        borderRadius: "8px"
    },
    [`& .${storiesListClasses.cardContent}`]: {
        padding: "5px 0",
        [`& h6`]: {
            fontSize: "18px",
            fontWeight: "normal",
            lineHeight: "24px",
            color: "#011E3F",
            marginBottom: "20px"
        },
    },
    [`& .${storiesListClasses.button}`]: {
        fontSize: "16px",
        fontWeight: "normal",
        background: "#011E3F",
        textTransform: "capitalize",
        borderRadius: "10px",
        height: "48px",
        minWidth: "185px"
    },
    [`& .${storiesListClasses.keywords}`]: {
        margin: "15px 0 10px",
        display: "flex",
        gap: "4px",
    },
    [`& .${storiesListClasses.keywordtag}`]: {
        fontSize: "14px",
        lineHeight: "17px",
        fontWeight: "400",
        background: "#011e3c0d",
        color: "#011e3c",
        borderRadius: "50px",
        padding: "5px 10px",
        cursor: "pointer"
    },
}));

