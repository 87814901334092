import {Grid, styled} from "@mui/material";

const prefix = "VideoTellingCaption";

export const videoTellingCaptionClasses = {
    root: `${prefix}-root`,
    leftCol: `${prefix}-leftCol`,
    title: `${prefix}-title`,
    titleWrapper: `${prefix}-titleWrapper`,
    smallBrush: `${prefix}-smallBrush`,
    description: `${prefix}-description`,
    nextStory: `${prefix}-nextStory`,
    rightCol: `${prefix}-rightCol`,
    brush: `${prefix}-brush`,
    card: `${prefix}-card`,
    cardLocation: `${prefix}-cardLocation`,
    cardImg: `${prefix}-cardImg`,
    cardContent: `${prefix}-cardContent`,
};

export const StyledVideoTellingCaption = styled(Grid)(({ theme }) => ({
    [`&.${videoTellingCaptionClasses.root}`]: {
        background: "radial-gradient(at 10% 100%, #375271 0%, #0F2F4B 34%) 0% 0% no-repeat",
        color: "#fff",
    },
    [`& .${videoTellingCaptionClasses.leftCol}`]: {
        paddingTop: "150px"
    },
    [`& .${videoTellingCaptionClasses.titleWrapper}`]: {
        position: "relative"
    },
    [`& .${videoTellingCaptionClasses.title}`]: {
        fontSize: "80px",
        fontWeight: "normal",
        lineHeight: "normal",
        padding: "0 0 30px 30px",
        position: "relative",
        zIndex: 2
    },
    [`& .${videoTellingCaptionClasses.smallBrush}`]: {
        position: "absolute",
        left: "-5px",
        bottom: "35px",
        zIndex: 1
    },
    [`& .${videoTellingCaptionClasses.description}`]: {
        fontSize: "24px",
        lineHeight: "24px",
        opacity: "0.8",
        marginBottom: "70px"
    },
    [`& .${videoTellingCaptionClasses.nextStory}`]: {
        justifyContent: "end",
        alignItems: "center",
        [`& h5, h6`]: {
            fontSize: "16px",
            fontWeight: "normal",
            lineHeight: "normal"
        },
        [`& h6`]: {
            opacity: "0.8",
            marginLeft: "25px"
        },
        [`& svg`]: {
            width: "16px",
            color: "#E38A15",
            marginLeft: "40px"
        },
    },
    [`& .${videoTellingCaptionClasses.rightCol}`]: {
        position: "relative",
        paddingTop: "85px",
        alignItems: "baseline",
        justifyContent: "center"
    },
    [`& .${videoTellingCaptionClasses.brush}`]: {
        position: "absolute",
        top: "-35px",
        left: "-20px",
        zIndex: 91
    },
    [`& .${videoTellingCaptionClasses.card}`]: {
        background: "transparent",
        color: "#fff",
        boxShadow: "none",
        position: "relative",
        zIndex: 92
    },
    [`& .${videoTellingCaptionClasses.cardLocation}`]: {
        position: "absolute",
        width: "100%",
        top: "0",
        padding: "10px",
        alignItems: "center",
        [`& img`]: {
            marginRight: "15px"
        },
        [`& h6`]: {
            fontWeight: "normal"
        },
    },
    [`& .${videoTellingCaptionClasses.cardImg}`]: {
        height: "250px",
        borderRadius: "8px"
    },
    [`& .${videoTellingCaptionClasses.cardContent}`]: {
        padding: "5px 0",
        [`& h6`]: {
            fontSize: "18px",
            fontWeight: "normal",
            lineHeight: "24px"
        },
    },
}));

