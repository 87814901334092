import { Grid, styled } from "@mui/material";

const STORY_CAPTION_PREFIX = "StoryCaption";

export const storyCaptionClasses = {
  root: `${STORY_CAPTION_PREFIX}-root`,
  leftCol: `${STORY_CAPTION_PREFIX}-leftCol`,
  leftColBrush: `${STORY_CAPTION_PREFIX}-leftColBrush`,
  rightCol: `${STORY_CAPTION_PREFIX}-rightCol`,
  brush: `${STORY_CAPTION_PREFIX}-brush`,
  countryImage: `${STORY_CAPTION_PREFIX}-countryImage`,
  nextStory: `${STORY_CAPTION_PREFIX}-nextStory`,
  nextStoryText: `${STORY_CAPTION_PREFIX}-nextStoryText`,
  nextStoryCountry: `${STORY_CAPTION_PREFIX}-nextStoryCountry`,
  circleCover: `${STORY_CAPTION_PREFIX}-circleCover`,
  circle: `${STORY_CAPTION_PREFIX}-circle`,
  sixtySecondsCountry: `${STORY_CAPTION_PREFIX}-sixtySecondsCountry`,
  smallBrush: `${STORY_CAPTION_PREFIX}-smallBrush`,
  smallBrushHeading: `${STORY_CAPTION_PREFIX}-smallBrushHeading`,
  downloadButton: `${STORY_CAPTION_PREFIX}-downloadButton`,
};

export const StyledStoryCaption = styled(Grid)(({ theme }) => ({
  [`&.${storyCaptionClasses.root}`]: {
    background:
      "radial-gradient(at 10% 100%, #375271 0%, #0F2F4B 34%) 0% 0% no-repeat",
    color: "#fff",
    paddingBottom: "20px",
    height: "530px",
  },
  [`& .${storyCaptionClasses.leftCol}`]: {
    position: "relative",
    zIndex: "99",
    [`& span`]: {
      fontSize: "11px",
      color: "#2C4560",
      opacity: "0.5",
    },
    [`& .${storyCaptionClasses.leftColBrush}`]: {
      position: "absolute",
      left: "-170px",
      bottom: "-100px",
    },
    [`& .${storyCaptionClasses.countryImage}`]: {
      position: "relative",
      zIndex: "9",
    },
  },
  [`& .${storyCaptionClasses.rightCol}`]: {
    position: "relative",
    paddingTop: "80px",
    alignItems: "baseline",
    justifyContent: "center",
  },
  [`& .${storyCaptionClasses.brush}`]: {
    position: "absolute",
    top: "-35px",
    left: "-40px",
    zIndex: 91,
  },
  [`& .${storyCaptionClasses.nextStory}`]: {
    marginTop: "180px",
    [`& > div`]: {
      position: "relative",
      maxWidth: "170px",
    },
    [`& .${storyCaptionClasses.nextStoryText}`]: {
      fontSize: "18px",
      position: "relative",
      zIndex: "2",
    },
    [`& .${storyCaptionClasses.nextStoryCountry}`]: {
      position: "relative",
      [`& p`]: {
        fontSize: "24px",
        fontWeight: "900",
        lineHeight: "32px",
        position: "relative",
        zIndex: "2",
      },
      [`& .${storyCaptionClasses.smallBrush}`]: {
        position: "absolute",
        left: "-10px",
        bottom: "0",
        zIndex: 1,
      },
    },
    [`& span`]: {
      fontSize: "14px",
      color: "#fff",
      opacity: "0.7",
      position: "relative",
      zIndex: "2",
    },
  },
  [`& .${storyCaptionClasses.circle}`]: {
    width: "123px",
    height: "123px",
    background: "transparent",
    border: "solid 2px #fff",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    top: "-13px",
    right: "-80px",
    [`& img`]: {
      zIndex: "9",
    },
    cursor: "pointer",
  },
  [`& .${storyCaptionClasses.circleCover}`]: {
    width: "129px",
    height: "129px",
    background:
      "transparent linear-gradient(90deg, #162E48 0%, #22395433 100%) 0% 0% no-repeat padding-box",
    borderRadius: "50%",
    position: "absolute",
    top: "-2px",
    left: "-2px",
  },
  [`& .${storyCaptionClasses.sixtySecondsCountry}`]: {
    [`& > div`]: {
      position: "relative",
      [`& h2`]: {
        lineHeight: "79px",
        fontWeight: "900",
        marginBottom: "30px",
        position: "relative",
        zIndex: "2",
      },
    },
    [`& .${storyCaptionClasses.smallBrush}`]: {
      position: "absolute",
      left: "-15px",
      bottom: "7px",
    },
    [`& p`]: {
      fontSize: "18px",
      lineHeight: "30px",
      fontWeight: "900",
      color: "white",
      opacity: "0.7",
      width: "100%",
    },
  },
  [`& .${storyCaptionClasses.downloadButton}`]: {
    fontSize: "16px",
    fontWeight: "normal",
    color: "#011E3F",
    background: "white",
    textTransform: "capitalize",
    borderRadius: "10px",
    height: "48px",
    minWidth: "185px",
  },
}));
