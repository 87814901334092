import { Container, styled } from "@mui/material";

const TAB_ABOUT_PREFIX = "TabAbout";

export const tabAboutClasses = {
  root: `${TAB_ABOUT_PREFIX}-root`,
  aboutImg: `${TAB_ABOUT_PREFIX}-aboutImg`,
  aboutImgBrush: `${TAB_ABOUT_PREFIX}-aboutImg`,
};

export const StyledTabAbout = styled(Container)(({ theme }) => ({
  [`& .${tabAboutClasses.aboutImg}`]: {
    // paddingLeft: "28px",
    position: "relative",
    marginBottom: "30px",
    [`& img`]: {
      width: "calc(100% - 40px)",
      position: "relative",
      zIndex: "9",
    },
    [`& .${tabAboutClasses.aboutImgBrush}`]: {
      position: "absolute",
      top: "-50px",
      left: "-135px",
      zIndex: "8",
    },
  },
}));
