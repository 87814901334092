import { FC } from "react";
import {
  storyAccordionClasses,
  StyledStoryAccordion,
} from "./StyledStoryAccordion";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import challenge from "./images/challenge.png";
import intervention from "./images/intervention.png";
import outcomes from "./images/outcomes.png";

import { IStory } from "../../interfaces/IStory";

interface IStoryAccordion {
  accordionSections: string[];
  story: IStory;
}

export const StoryAccordion: FC<IStoryAccordion> = ({
  accordionSections,
  story,
}) => {
  const displayOutcomes = (outcome: {
    type: "bullets" | "text";
    bullets?: string[];
    text?: string;
    header?: string;
  }) => {
    if (outcome.type === "text") {
      return (
      <Typography
        component="div" // Add the component prop with the value "div"
        sx={{
          fontSize: "18px",
          lineHeight: "26px",
          color: "#011E3F",
          fontFamily: "Inter",
          fontWeight: "100",
          padding: "0 20px 20px 20px",
        }}
        dangerouslySetInnerHTML={{ __html: outcome.text ?? "" }}
      ></Typography>
      )
    } else {
      return (
          <>
          {outcome.header && (<Typography
                    component="div"
                    key={outcome.header}
                    sx={{
                      textAlign: "left",
                      fontSize: "18px",
                      lineHeight: "26px",
                      color: "#011E3F",
                      fontFamily: "Inter",
                      fontWeight: "100",
                      marginBottom: "20px",
                    }}
                >
                  {outcome.header}
                </Typography>
          )}
        <ul style={{ margin: 0 }}>
          {outcome.bullets!.map((source: string, index: number) => {
            return (
              <Typography
                component="li"
                key={index}
                sx={{
                  textAlign: "left",
                  fontSize: "18px",
                  lineHeight: "26px",
                  color: "#011E3F",
                  fontFamily: "Inter",
                  fontWeight: "100",
                  marginBottom: "20px",
                }}
              >
                <span dangerouslySetInnerHTML={{ __html: source }} />
              </Typography>
            );
          })}
        </ul>
          </>
      );
    }
  };

  return (
    <StyledStoryAccordion className={storyAccordionClasses.root}>
      {accordionSections.map((section, index) => (
        <Accordion
          key={section}
          defaultExpanded={index === 0}
          className={storyAccordionClasses.accordion}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id={`panel1a-header-${section}`}
            className={storyAccordionClasses.accordionSummary}
          >
            <Typography>{section}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {index === 0 && (
              <div>
                <img
                  src={challenge}
                  alt=""
                  style={{ float: "left", padding: "45px 100px" }}
                />
                <Typography
                  sx={{
                    fontSize: "18px",
                    lineHeight: "26px",
                    color: "#011E3F",
                    fontFamily: "Inter",
                    fontWeight: "100",
                    padding: "0 20px 20px 20px",
                  }}
                  dangerouslySetInnerHTML={{
                    __html: story.sexualAndReproductiveHealthChallenge,
                  }}
                ></Typography>
              </div>
            )}
            {index === 1 && (
              <div>
                <img
                  src={intervention}
                  alt=""
                  style={{ float: "left", padding: "45px 100px" }}
                />
                <Typography
                  sx={{
                    fontSize: "18px",
                    lineHeight: "26px",
                    color: "#011E3F",
                    fontFamily: "Inter",
                    fontWeight: "100",
                    padding: "0 20px 20px 20px",
                  }}
                  dangerouslySetInnerHTML={{
                    __html:
                      story.sexualAndReproductiveHealthUniversalHealthCoverageIntervention,
                  }}
                ></Typography>

                {story.sexualAndReproductiveHealthUniversalHealthCoverageIntervention && 
                 story.sexualAndReproductiveHealthUniversalHealthCoverageInterventionBullets && (
                  <div style={{ clear: "both" }}></div>
                )}

                {story.sexualAndReproductiveHealthUniversalHealthCoverageInterventionBullets && (
                  <ul style={{ padding: "0 20px 20px 40px" }}>
                    {story.sexualAndReproductiveHealthUniversalHealthCoverageInterventionBullets.map((bullet, i) => (
                      <li
                        key={i}
                        style={{
                          fontSize: "18px",
                          lineHeight: "26px",
                          color: "#011E3F",
                          fontFamily: "Inter",
                          fontWeight: "100",
                          marginBottom: "10px"
                        }}
                        dangerouslySetInnerHTML={{
                          __html: bullet
                        }}
                      >
                      </li>
                    ))}
                  </ul>
                )}
                {/*<img*/}
                {/*  src={accordionImg}*/}
                {/*  alt=""*/}
                {/*  style={{ width: "100%", padding: "0 20px" }}*/}
                {/*/>*/}
              </div>
            )}
            {index === 2 && (
              <Grid container>
                {story.sexualAndReproductiveHealthUniversalHealthCoverageOutcomes && (
                  <Typography
                    sx={{
                      fontSize: "18px",
                      lineHeight: "26px",
                      color: "#011E3F",
                      fontFamily: "Inter",
                      fontWeight: "100",
                      padding: "0 20px 20px 20px",
                    }}
                    dangerouslySetInnerHTML={{
                      __html:
                        story.sexualAndReproductiveHealthUniversalHealthCoverageOutcomes,
                    }}
                    >
                  </Typography>
                )}
                {story.success && (
                  <Grid container>
                    <Typography sx={{ fontWeight: "bold", margin: "10px 0" }}>
                      Success
                    </Typography>
                    <div>
                      <img
                        src={outcomes}
                        alt=""
                        style={{ float: "left", padding: "45px 100px" }}
                      />

                      {story.success.body && (
                        <Typography
                          component="div"
                          sx={{
                            fontSize: "18px",
                            lineHeight: "26px", 
                            color: "#011E3F",
                            fontFamily: "Inter",
                            fontWeight: "100",
                            padding: "0 20px 20px 20px",
                          }}
                          dangerouslySetInnerHTML={{ __html: story.success.body }}
                          ></Typography>
                        )}

                        {story.success.body && story.success.bullets && (
                          <div style={{ clear: "both" }}></div>
                        )}

                      {displayOutcomes(story.success)}
                    </div>
                  </Grid>
                )}
                {story.challenges && (
                  <Grid container>
                    <Typography sx={{ fontWeight: "bold", margin: "10px 0" }}>
                      Challenges
                    </Typography>
                    <div>
                      <img
                        src={outcomes}
                        alt=""
                        style={{ float: "left", padding: "45px 100px" }}
                      />
                      {displayOutcomes(story.challenges)}
                    </div>
                  </Grid>
                )}
              </Grid>
            )}
            {index === 3 && (
                <Grid container>
                  {story.lessons && (
                      <Grid container>
                        {displayOutcomes(story.lessons)}
                      </Grid>
                  )}
                </Grid>
            )}
          </AccordionDetails>
        </Accordion>
      ))}
    </StyledStoryAccordion>
  );
};
