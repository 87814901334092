import { aboutCaptionClasses, StyledAboutCaption } from "./StyledAboutCaption";
import { Container, Grid, Typography, Tab } from "@mui/material";
import { TabList } from "@mui/lab";

import aboutHero from "../../assets/aboutHero.jpg";

export const AboutCaption = (props: any) => {

  const mainContainerStyle = {
      backgroundImage: 'url('+aboutHero+')',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
  }

  const barContainerStyle = {
      backgroundColor: '#009ADE',
      textAlign: 'center',
  }

  return (
    <StyledAboutCaption className={aboutCaptionClasses.root}>
        <Grid item sx={mainContainerStyle} className="heroClass">
            <Container maxWidth={"lg"}>
                <Grid container sx={{paddingTop: "150px", paddingBottom: "55px", position: "relative", minHeight: "calc(100vh - 220px)"}}>
                    <Grid item md={8} lg={6}>
                        <Grid container sx={{position: "relative", backgroundColor:"rgba(255,255,255,0.75)", padding:"40px 20px 20px", marginBottom: "40px" }}>
                            <Typography variant={"caption"} component={"div"} sx={{color: "#011E3F", textAlign: "left", zIndex: 100,  position: "relative"}}>
                            Our Approach
                            </Typography>
                            <Typography sx={{color: "#011E3F", margin: "30px 0 20px", textAlign: "left", fontSize: "20px"}}>
                            The SRHR-UHC Learning by Sharing Portal (LSP) was launched in July 2022, to support countries to prioritize and expand coverage of SRHR services.
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                {/* FIXME: Dont do this*/}
                <Grid item sx={{"height": "50px", width: "100%"}} />
            </Container>
        </Grid>
        <Grid item sx={barContainerStyle}>
            <Container maxWidth={"xl"}>
                <Grid container sx={{padding: "0", position: "relative" }} justifyContent={"center"}>
                    <Grid item lg={0}>
                    </Grid>
                    <Grid item xs={12} lg={9} xl={8}>
                        <Grid container sx={{position: "relative", padding:"0", justifyContent: "center", width:"100%" }}>
                          <TabList
                            onChange={props.handleChangeTabValue}
                            className={aboutCaptionClasses.tabs}
                            variant="fullWidth"
                          >
                            <Tab
                              label={
                                <Grid item>
                                  <Typography>About</Typography>
                                  <span className="hideOnXs text-normal">More information about the LSP</span>
                                </Grid>
                              }
                              value="1"
                            />
                            <Tab
                              label={
                                <Grid item>
                                  <Typography>Our approach</Typography>
                                  <span className="hideOnXs text-normal">Learn about our process</span>
                                </Grid>
                              }
                              value="2"
                            />
                            <Tab
                              label={
                                <Grid item>
                                  <Typography>Expert review group</Typography>
                                  <span className="hideOnXs text-normal">Who's onboard with us</span>
                                </Grid>
                              }
                              value="3"
                            />
                          </TabList>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Grid>
    </StyledAboutCaption>
  );
};
