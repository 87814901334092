import {Grid, styled} from "@mui/material";

const prefix = "Quotes";

export const quotesClasses = {
    textBrush: `${prefix}-textBrush`,
    feather: `${prefix}-feather`,
};

export const StyledQuotes = styled(Grid)(({ theme }) => ({
    margin: "30px 0",
    [`& .${quotesClasses.textBrush}`]: {
        position: "absolute",
        zIndex: 1,
        top: "50%",
        left: "40%",
    },
    [`& .${quotesClasses.feather}`]: {
        position: "absolute",
        zIndex: 1,
        top: "-70px",
        left: "-120px"
    },
}));
