import React, { useState } from "react";
import { storyCaptionClasses, StyledStoryCaption } from "./StyledStoryCaption";
import { Button, Container, Grid, Menu, MenuItem, Typography } from "@mui/material";

import smallYellowBrush from "../../../assets/very-small-brush-yellow.png";
import captionBrush from "../../../assets/story-caption-brush.png";
import rightArrow from "./images/right-arrow.png";

import { IStory } from "../../interfaces/IStory";
import { stories } from "../../stories-items";
import { useNavigate } from "react-router-dom";
import { NavigationRoutes } from "../../../routes";
import DownloadIcon from "@mui/icons-material/Download";

export const StoryCaption = ({ story }: { story: IStory }) => {
  const nextStoryIndex = parseInt(story.id);
  const nextStory = stories[nextStoryIndex] || stories[0];
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const downloadPdf = (url: string)=> {
    const a = document.createElement('a');
    a.href = url;
    a.download = url.split("/")[2];
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    handleClose();
  }

  return (
    <StyledStoryCaption className={storyCaptionClasses.root}>
      <Container>
        <Grid container sx={{ margin: "0 -24px" }}>
          <Grid item lg={7} className={storyCaptionClasses.leftCol}>
            <Grid
              item
              container
              flexDirection="column"
              alignItems="baseline"
              justifyContent="space-between"
              sx={{ margin: "40px 0" }}
              lg={12}>
              <div className={storyCaptionClasses.sixtySecondsCountry}>
                <div>
                  <Typography variant={"h2"} sx={{ color: storyCaptionClasses, fontSize: "72px" }}>
                    {story.country}
                  </Typography>
                  <img
                    src={smallYellowBrush}
                    alt={"small-brush"}
                    className={storyCaptionClasses.smallBrush}
                  />
                </div>
                <div style={{ display: "flex"}}>
                  <Typography sx={{ marginRight: "25px" }}>{story.header}</Typography>
                  { story.pdfs ? (
                      <>
                        <Button
                            id="demo-positioned-button"
                            variant={"contained"}
                            onClick={handleClick}
                            className={storyCaptionClasses.downloadButton}
                        >
                          <DownloadIcon />
                          Download story
                        </Button>
                        <Menu
                            id="demo-positioned-menu"
                            aria-labelledby="demo-positioned-button"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            anchorOrigin={{
                              vertical: 'top',
                              horizontal: 'left',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'left',
                            }}
                        >
                          {story.pdfs.map((pdf)=>{
                            return  <MenuItem onClick={()=>downloadPdf(pdf.url)}>{pdf.language}</MenuItem>
                          })}
                        </Menu>
                      </>
                  ) : (
                      <Button
                          variant={"contained"}
                          component={"a"}
                          href={story.pdf}
                          download
                          className={storyCaptionClasses.downloadButton}
                      >
                        <DownloadIcon />
                        Download story
                      </Button>
                  )}
                </div>
              </div>
            </Grid>
            <img
              src={story.image}
              alt=""
              className={storyCaptionClasses.countryImage}
              width={680}
            />
            <img
              src={captionBrush}
              alt=""
              className={storyCaptionClasses.leftColBrush}
            />
            <span style={{ display: "block"}}>Copyright - {story.imageCredit}</span>
          </Grid>
          <Grid item lg={5} className={storyCaptionClasses.rightCol}>
            <img
              src={captionBrush}
              alt={"brush"}
              className={storyCaptionClasses.brush}
            />
            <Grid
              container
              flexDirection="column"
              alignItems="center"
              className={storyCaptionClasses.nextStory}
            >
              <div>
                <Typography className={storyCaptionClasses.nextStoryText}>
                  Next Story
                </Typography>
                <div className={storyCaptionClasses.nextStoryCountry}>
                  <Typography>{nextStory.country}</Typography>
                  <img
                    src={smallYellowBrush}
                    alt={"small-brush"}
                    className={storyCaptionClasses.smallBrush}
                  />
                </div>
                <span>{nextStory.header.slice(0, 30)}...</span>
                <div className={storyCaptionClasses.circle} onClick={()=>{
                  navigate(`/${NavigationRoutes.Story}/${nextStory.id}`)
                }}>
                  <div className={storyCaptionClasses.circleCover} />
                  <img src={rightArrow} alt="Arrow" />
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </StyledStoryCaption>
  );
};
