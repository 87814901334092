import {Grid, styled} from "@mui/material";

const prefix = "Admin";

export const adminClasses = {
    root: `${prefix}-root`,
};

export const StyledAdmin = styled(Grid)(({ theme }) => ({
    [`&.${adminClasses.root}`]: {},
}));

