// YouTubeModal.tsx
import React from "react";
import Modal from "react-modal";
import YouTube from "react-youtube";

interface YouTubeModalProps {
  isOpen: boolean;
  videoId: string;
  onClose: () => void;
}

const YouTubeModal: React.FC<YouTubeModalProps> = ({ isOpen, videoId, onClose }) => {
  const opts = {
    height: "100%",
    width: "100%",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="YouTube Modal"
      style={{
        overlay: {
          backgroundColor: "rgba(10, 31, 50, 0.9)",
          backdropFilter: "blur(5px)",
          zIndex: 1000,
        },
        content: {
          backgroundColor: "#000",
          padding: 0,
          border: "none",
          width: "960px",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          maxWidth: "80%",
          inset: "50% 40px auto 50%",
        },
      }}
    >
      <div className="videoWrapper" style={{ position: "relative", paddingBottom: "56.5%", width: "100%" }}>
      <YouTube videoId={videoId} opts={opts} />
      </div>
      <div style={{ padding: "0", textAlign: "center" }}>
      <button className="closeModalButton" onClick={onClose}>Close Video</button>
      </div>
    </Modal>
  );
};

export default YouTubeModal;
