import {Grid, styled} from "@mui/material";
import {primary} from "../common/theme";

const FOOTER_PREFIX = "Footer";

export const footerClasses = {
    root: `${FOOTER_PREFIX}-root`,
    logo: `${FOOTER_PREFIX}-logo`,
    navigation: `${FOOTER_PREFIX}-navigation`,
    container: `${FOOTER_PREFIX}-container`,
    topContainer: `${FOOTER_PREFIX}-topContainer`,
    contactLabel: `${FOOTER_PREFIX}-contactLabel`,
    contactText: `${FOOTER_PREFIX}-contactText`,
    bottomContainer: `${FOOTER_PREFIX}-bottomContainer`,
    bottomContainerText: `${FOOTER_PREFIX}-bottomContainerText`,
    bottomContainerTextRight: `${FOOTER_PREFIX}-bottomContainerTextRight`,
    bottomContainerTextMail: `${FOOTER_PREFIX}-bottomContainerTextMail`,
    bottomWrapper: `${FOOTER_PREFIX}-bottomWrapper`,
    joinUsRibbon: `${FOOTER_PREFIX}-joinUsRibbon`,
    joinUsRibbonContainer: `${FOOTER_PREFIX}-joinUsRibbonContainer`,
};

export const StyledFooter = styled(Grid)(({ theme }) => ({
    [`&.${footerClasses.root}`]: {
        borderTop: "1px solid #EFF3F5"
    },
    [`& .${footerClasses.container}`]: {
        display: "flex",
        flexDirection: "column",
    },
    [`& .${footerClasses.bottomWrapper}`]: {
        display: "flex",
        flexDirection: "column",
        background: "#EFF3F5",
        borderTop: "1px solid #EFF3F5"
    },
    [`& .${footerClasses.joinUsRibbon}`]: {
        display: "flex",
        flexDirection: "column",
        background: "#E38A15",
        borderTop: "1px solid #EFF3F5"
    },
    [`& .${footerClasses.topContainer}`]: {
        // height: "270px",
        padding: "30px 0",
    },
    [`& .${footerClasses.logo}`]: {
        marginRight: '30px'
    },
    [`& .${footerClasses.navigation}`]: {
        fontSize: 16,
        color: primary,
        marginBottom: "20px",
        fontWeight: "normal",
        cursor: "pointer",
    },
    [`& .${footerClasses.contactText}`]: {
        fontSize: 14,
        color: "#2C4560",
        opacity: "0.65",
        textAlign: "left"
    },
    [`& .${footerClasses.contactLabel}`]: {
        fontSize: 14,
        color: primary,
        fontWeight: "normal",
        marginBottom: "10px",
        textAlign: "left"
    },
    [`& .${footerClasses.bottomContainerText}`]: {
        fontSize: 14,
        color: "#011E3F",
        opacity: "0.6",
        fontWeight: "normal",
        textAlign: "left"
    },
    [`& .${footerClasses.bottomContainerTextRight}`]: {
        fontSize: 16,
        color: "#011E3F",
        opacity: "0.6",
        fontWeight: "normal",
        textAlign: "right",
        textDecoration: "none",
        marginBottom: "20px",
    },
    [`& .${footerClasses.bottomContainerTextMail}`]: {
        fontSize: 18,
        color: "white",
        opacity: "0.6",
        fontWeight: "bold",
        textAlign: "center",
        marginBottom: "10px",
        marginTop: "10px",
        marginLeft: "10px",
    },
    [`& .${footerClasses.bottomContainer}`]: {
        // height: "70px",
        padding: "20px 0",
    },
    [`& .${footerClasses.joinUsRibbonContainer}`]: {
        // height: "40px",
        padding: "10px 0",
    },
}));


