import {Grid, styled} from "@mui/material";

const ABOUT_PREFIX = "About";

export const aboutClasses = {
    root: `${ABOUT_PREFIX}-root`,
};

export const StyledAbout = styled(Grid)(({ theme }) => ({
    [`&.${aboutClasses.root}`]: {},
}));

