import {Grid, styled} from "@mui/material";

const STORIES_PREFIX = "Stories";

export const storiesClasses = {
    root: `${STORIES_PREFIX}-root`,
};

export const StyledStories = styled(Grid)(({ theme }) => ({
    [`&.${storiesClasses.root}`]: {},
}));

