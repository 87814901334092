import {Grid, styled} from "@mui/material";

const prefix = "VideoTelling";

export const videoTellingClasses = {
    root: `${prefix}-root`,
    textBrush: `${prefix}-textBrush`,
    card: `${prefix}-card`,
    cardLocation: `${prefix}-cardLocation`,
    cardImg: `${prefix}-cardImg`,
    cardContent: `${prefix}-cardContent`,
};

export const StyledVideoTelling = styled(Grid)(({ theme }) => ({
    [`&.${videoTellingClasses.root}`]: {},
    [`& .${videoTellingClasses.textBrush}`]: {
        position: "absolute",
        zIndex: 1,
        top: "90%",
        left: "40%",
    },
    [`& .${videoTellingClasses.card}`]: {
        background: "transparent",
        boxShadow: "none",
        position: "relative",
    },
    [`& .${videoTellingClasses.cardLocation}`]: {
        position: "absolute",
        width: "100%",
        top: "0",
        padding: "10px",
        alignItems: "center",
        [`& img`]: {
            marginRight: "15px"
        },
        [`& h6`]: {
            fontWeight: "normal"
        },
    },
    [`& .${videoTellingClasses.cardImg}`]: {
        height: "250px",
        borderRadius: "8px",
    },
    [`& .${videoTellingClasses.cardContent}`]: {
        padding: "5px 0",
        height: "150px",
        [`& h6`]: {
            fontSize: "18px",
            fontWeight: "normal",
            lineHeight: "24px",
            color: "#011E3F",
            marginBottom: "20px"
        },
    },
}));

