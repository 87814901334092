import { useState } from "react";
import { TabContext } from "@mui/lab";
import { aboutClasses, StyledAbout } from "./StyledAbout";
import { ViewContainer } from "../ViewContainer/ViewContainer";
import { Mode } from "../interfaces/mode";
import { AboutTabs } from "./AboutTabs/AboutTabs";
import { AboutCaption } from "./AboutCaption/AboutCaption";
import { useGA } from "../common/hooks";

export const About = () => {
  useGA("About");
  const [tabValue, setTabValue] = useState("1");

  const handleChangeTabValue = (event: any, newValue: string) => {
    setTabValue(newValue);
  };

  return (
    <ViewContainer mode={Mode.Dark}>
      <StyledAbout className={aboutClasses.root}>
        <TabContext value={tabValue}>
          <AboutCaption handleChangeTabValue={handleChangeTabValue} />
          <AboutTabs />
        </TabContext>
      </StyledAbout>
    </ViewContainer>
  );
};
