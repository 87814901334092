import {Grid, styled} from "@mui/material";

const HOME_PREFIX = "Home";

export const homeClasses = {
    root: `${HOME_PREFIX}-root`,
};

export const StyledHome = styled(Grid)(({ theme }) => ({
    [`&.${homeClasses.root}`]: {},
}));

