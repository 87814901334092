import { Container, styled } from "@mui/material";

const TAB_EXPERT_REVIEW_PREFIX = "TabExpertReview";

export const tabExpertReviewClasses = {
  root: `${TAB_EXPERT_REVIEW_PREFIX}-root`,
  card: `${TAB_EXPERT_REVIEW_PREFIX}-card`,
};

export const StyledTabExpertReview = styled(Container)(({ theme }) => ({
  [`& .${tabExpertReviewClasses.card}`]: {
    textAlign: "center",
    boxShadow: "none",
    [`& > div`]: {
      margin: "0 auto 0",
    },
    [`& h4`]: {},
  },
}));
