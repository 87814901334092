import { useState } from "react";
import { useParams } from "react-router-dom";
import { storyClasses, StyledStory } from "./StyledStory";
import { Button, Container, Grid, Typography } from "@mui/material";
import { StoryCaption } from "./StoryCaption/StoryCaption";
import { StoryAccordion } from "./StoryAccordion/StoryAccordion";
import { ViewContainer } from "../../ViewContainer/ViewContainer";
import { LatestStories } from "../../Home/LatestStories/LatestStories";
import { Mode } from "../../interfaces/mode";
import { primary } from "../../common/theme";

import { stories } from "../stories-items";
import { IStory } from "../interfaces/IStory";

import brush from "../../assets/brush15.png";
import clockIcon from "./images/clock-icon.png";
import { useGA } from "../../common/hooks";

export const Story = () => {
  const { storyId } = useParams();
  useGA(`Story ${storyId}`);
  const story = stories.find(
    (innerStory: IStory) => innerStory.id === storyId
  ) as IStory;
  const [toggleAuthors, setToggleAuthors] = useState(false);
  const [toggleSources, setToggleSources] = useState(false);

  const accordionSections = [
    "Sexual and Reproductive Health & Rights Challenge",
    `Intervention`,
    "Outcomes",
    "Lessons",
  ];

  return (
    <ViewContainer mode={Mode.Dark}>
      <StyledStory className={storyClasses.root}>
        {story && <StoryCaption story={story} />}
        <Container maxWidth={"lg"}>
          <Grid container className={storyClasses.sixtySeconds}>
            <Grid item lg={12}>
              <Grid container alignItems="center" sx={{ marginBottom: "40px" }}>
                <img
                  src={clockIcon}
                  alt="icon"
                  style={{ width: "25px", height: "27px", marginRight: "15px" }}
                />
                <Typography
                  variant="h2"
                  sx={{ color: primary, fontWeight: "900" }}
                >
                  60 SECOND SUMMARY
                </Typography>
              </Grid>
              <Typography className={storyClasses.quote}>
                {story.sixtySecondsSummary}
              </Typography>
            </Grid>
          </Grid>
          <StoryAccordion accordionSections={accordionSections} story={story} />
          <Grid className={storyClasses.sources}>
            <img
              src={brush}
              alt={"brush"}
              className={storyClasses.imageBrush}
            />
            <Typography variant={"h5"}>
              <span style={{marginRight: 15}}>Authors</span>
              <Button onClick={() => setToggleAuthors(!toggleAuthors)} variant={"outlined"} sx={{height: 30}}>
                {toggleAuthors ? "Show less" : "Show more"}
              </Button>
            </Typography>
            {toggleAuthors ? (
              <>
                <ul>
                  {story.authors.map((source: string, index: number) => {
                    return (
                      <Typography
                        component="li"
                        key={index}
                        sx={{
                          textAlign: "left",
                          fontSize: "12px",
                          lineHeight: "26px",
                          color: "#011E3F",
                          fontFamily: "Inter",
                          fontWeight: "100",
                        }}
                      >
                        {source}
                      </Typography>
                    );
                  })}
                </ul>
                {/*<Button onClick={() => setToggleAuthors(!toggleAuthors)}>*/}
                {/*  {toggleAuthors ? "Show less" : "Show more"}*/}
                {/*</Button>*/}
              </>
            ) : (
              <>
                <ul>
                  <Typography
                    component="li"
                    sx={{
                      textAlign: "left",
                      lineHeight: "26px",
                      color: "#011E3F",
                      fontFamily: "Inter",
                      fontWeight: "100",
                      fontSize: "12px",
                    }}
                  >
                    {story.authors[0]}
                  </Typography>
                </ul>
                {/*<Button onClick={() => setToggleAuthors(!toggleAuthors)}>*/}
                {/*  {toggleAuthors ? "Show less" : "Show more"}*/}
                {/*</Button>*/}
              </>
            )}

            {story.getInTouch && (
              <Grid
                container
                sx={{
                  color: "#000000",
                  fontSize: "18px",
                  fontWeight: "bold",
                  margin: "20px 0",
                }}
              >
                Get in touch with the corresponding author: {story.getInTouch}
              </Grid>
            )}

            <Typography variant={"h5"}>
              <span style={{marginRight: 15}}>Sources</span>
              <Button onClick={() => setToggleSources(!toggleSources)} variant={"outlined"} sx={{height: 30}}>
                {toggleSources ? "Show less" : "Show more"}
              </Button>
            </Typography>
            {toggleSources ? (
              <>
                <ul>
                  {story.sources.map((source: string, index: number) => {
                    return (
                      <Typography
                        component="li"
                        key={index}
                        sx={{
                          textAlign: "left",
                          lineHeight: "26px",
                          color: "#011E3F",
                          fontFamily: "Inter",
                          fontWeight: "100",
                          fontSize: "12px",
                        }}
                      >
                        {source}
                      </Typography>
                    );
                  })}
                </ul>
                {/*<Button onClick={() => setToggleSources(!toggleSources)}>*/}
                {/*  {toggleSources ? "Show less" : "Show more"}*/}
                {/*</Button>*/}
              </>
            ) : (
              <>
                <ul>
                  <Typography
                    component="li"
                    sx={{
                      textAlign: "left",
                      lineHeight: "26px",
                      color: "#011E3F",
                      fontFamily: "Inter",
                      fontWeight: "100",
                      fontSize: "12px",
                    }}
                  >
                    {story.sources[0]}
                  </Typography>
                </ul>
                {/*<Button onClick={() => setToggleSources(!toggleSources)}>*/}
                {/*  {toggleSources ? "Show less" : "Show more"}*/}
                {/*</Button>*/}
              </>
            )}
          </Grid>
        </Container>
        <LatestStories />
      </StyledStory>
    </ViewContainer>
  );
};
