import {Grid, styled} from "@mui/material";

const STORIES_FILTER_PREFIX = "StoriesFilter";

export const storiesFilterClasses = {
    root: `${STORIES_FILTER_PREFIX}-root`,
    selectCountry: `${STORIES_FILTER_PREFIX}-selectCountry`,
    selectCountryInput: `${STORIES_FILTER_PREFIX}-selectCountryInput`,
    button: `${STORIES_FILTER_PREFIX}-button`,
    selectAlphabet: `${STORIES_FILTER_PREFIX}-selectAlphabet`,
    selectAlphabetInput: `${STORIES_FILTER_PREFIX}-selectAlphabetInput`,
};

export const StyledStoriesFilter = styled(Grid)(({ theme }) => ({
    [`&.${storiesFilterClasses.root}`]: {
        paddingTop: "50px",
        alignItems: "center",
        justifyContent: "space-between",
    },
    [`& .${storiesFilterClasses.selectCountry}`]: {
        width: "500px",
        justifyContent: "space-between",
        alignItems: "center",
        borderRadius: "20px",
        boxShadow: "1px 3px 15px #00000029",
        padding: "10px"
    },
    [`& .${storiesFilterClasses.selectCountryInput}`]: {
        [`& svg`]: {
            color: "#E38A15"
        }
    },
    [`& .${storiesFilterClasses.button}`]: {
        fontSize: "16px",
        fontWeight: "normal",
        background: "#011E3F",
        textTransform: "capitalize",
        borderRadius: "10px",
        height: "60px",
        minWidth: "170px"
    },
    [`& .${storiesFilterClasses.selectAlphabet}`]: {
        [`& svg`]: {
            color: "#E38A15"
        }
    },
    [`& .${storiesFilterClasses.selectAlphabetInput}`]: {
        [`&:before`]: {
            display: "none"
        }
    },
}));
