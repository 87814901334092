import { Grid, styled } from "@mui/material";

const ABOUT_CAPTION_PREFIX = "AboutCaption";

export const aboutCaptionClasses = {
  root: `${ABOUT_CAPTION_PREFIX}-root`,
  aboutCol: `${ABOUT_CAPTION_PREFIX}-aboutCol`,
  imgCol: `${ABOUT_CAPTION_PREFIX}-imgCol`,
  imgColBorder: `${ABOUT_CAPTION_PREFIX}-imgColBorder`,
  imgColBorderCover: `${ABOUT_CAPTION_PREFIX}-imgColBorderCover`,
  imgColBorderImg: `${ABOUT_CAPTION_PREFIX}-imgColBorderImg`,
  imgColWrapper: `${ABOUT_CAPTION_PREFIX}-imgColWrapper`,
  imgColBrush: `${ABOUT_CAPTION_PREFIX}-imgColBrush`,
  tabsCol: `${ABOUT_CAPTION_PREFIX}-tabsCol`,
  tabs: `${ABOUT_CAPTION_PREFIX}-tabs`,
  captionBrush: `${ABOUT_CAPTION_PREFIX}-captionBrush`,
  titleWrapper: `${ABOUT_CAPTION_PREFIX}-titleWrapper`,
  captionYellowBrush: `${ABOUT_CAPTION_PREFIX}-captionYellowBrush`,
};

export const StyledAboutCaption = styled(Grid)(({ theme }) => ({
  [`&.${aboutCaptionClasses.root}`]: {
    background:
      "radial-gradient(at 10% 100%, #375271 0%, #0F2F4B 34%) 0% 0% no-repeat",
    marginBottom: "75px",
    [`& h2`]: {
      fontSize: "80px",
      lineHeight: "106px",
      fontWeight: "500",
      color: "#fff",
      maxWidth: "350px",
      marginBottom: "30px",
      position: "relative",
      zIndex: 2,
    },
    [`& h3`]: {
      fontSize: "24px",
      lineHeight: "32px",
      // color: "#fff",
      marginBottom: "25px",
    },
    [`& h4`]: {
      fontSize: "16px",
      lineHeight: "24px",
      color: "#fff",
      opacity: "0.6",
      fontWeight: "400",
    },
  },
  [`& .${aboutCaptionClasses.captionBrush}`]: {
    position: "absolute",
    left: "-30px",
    bottom: "200px",
    zIndex: 1,
  },
  [`& .${aboutCaptionClasses.aboutCol}`]: {
    paddingTop: "100px",
  },
  [`& .${aboutCaptionClasses.imgCol}`]: {
    paddingTop: "60px",
    paddingBottom: "70px",
  },
  [`& .${aboutCaptionClasses.imgColWrapper}`]: {
    position: "relative",
  },
  [`& .${aboutCaptionClasses.imgColBrush}`]: {
    position: "absolute",
    top: "-105px",
    left: "-180px",
    zIndex: "90",
  },
  [`& .${aboutCaptionClasses.imgColBorder}`]: {
    width: "350px",
    height: "350px",
    background: "transparent",
    border: "solid 2px #fff",
    borderRadius: "50%",
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  [`& .${aboutCaptionClasses.imgColBorderCover}`]: {
    width: "356px",
    height: "356px",
    background:
      "transparent linear-gradient(227deg, #162E48 0%, #22395433 100%) 0% 0% no-repeat padding-box",
    borderRadius: "50%",
    position: "absolute",
    top: "-2px",
    left: "-2px",
  },
  [`& .${aboutCaptionClasses.imgColBorderImg}`]: {
    position: "relative",
    width: "300px",
    height: "300px",
    borderRadius: "50%",
    overflow: "hidden",
    zIndex: "99",
    [`& img`]: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
  },
  [`& .MuiTab-root`]: {
    textTransform: "capitalize",
    fontSize: "18px",
    lineHeight: "24px",
    textAlign: "left",
    opacity: "1",
    color: "#FFF",
    alignItems: "baseline",
    padding: "8px 20px",
    borderLeft: "solid 2px #011E3F",
    [`@media (min-width: 900px)`]: {
      [`& .MuiTab-root`]: {
        whiteSpace: "nowrap",
      },
    },
    [`& span`]: {
      // opacity: "0.6",
      fontSize: "14px",
      lineHeight: "22px",
    },
    [`& p`]: {
      // opacity: "0.6",
      fontSize: "20px",
      lineHeight: "22px",
    },
    [`&.Mui-selected`]: {
      backgroundColor: '#011E3F',
      [`& span`]: {
        color: "#FFF",
        opacity: "1",
      },
      [`& p`]: {
        color: "#FFF",
        opacity: "1",
      },
    },
    [`& .MuiTabs-indicator`]: {
      display: "none",
    },
  },
  [`& .${aboutCaptionClasses.titleWrapper}`]: {
    position: "relative",
  },
  [`& .${aboutCaptionClasses.captionYellowBrush}`]: {
    position: "absolute",
    left: "-20px",
    bottom: "13px",
    zIndex: 1,
  },
}));
