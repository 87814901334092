import { StyledTabApproach, tabApproachClasses } from "./StyledTabApproach";
import { Grid, Typography } from "@mui/material";
import { primary } from "../../../common/theme";
import about1 from "./images/about-1.png";
import about2 from "./images/about-2.png";
import about3 from "./images/about-3.png";

export const TabApproach = () => {
  return (
    <StyledTabApproach className={tabApproachClasses.root}>
      <Typography sx={{ marginBottom: "30px" }}>
        The Sexual and Reproductive Health and Universal Health Coverage Learning by Sharing Portal (SRHR-UHC LSP) is a global resource offering insights into country level experience and best practice interventions for scale-up.
      </Typography>
      <Typography sx={{ marginBottom: "50px" }}>
        The SRHR-UHC LSP transforms complex evidence, policy, and programmatic experiences at the national level to short “easy to use” and digestable implementation stories. The implementation stories featured on the portal are co-produced by WHO, UNFPA and authors spanning across national level ministries of health, civil society including youth groups and academia.
      </Typography>

      <Typography variant={"h1"} component="div" sx={{color: primary, margin: "70px 0 40px 0", zIndex: 100}}>
        How are our implementation stories developed?
      </Typography>
      <Grid container justifyContent={"center"} columnSpacing={3}>
        <Grid item xs={12} sm={4} md={3} sx={{textAlign:"center"}}>
          <img src={about1} alt="How are stories sourced?" style={{ width:"100%", maxWidth:"190px" }} className="imgAboutShift"/>
        </Grid>
        <Grid item xs={12} sm={8} md={9} sx={{ margin:"20px 0 30px 0" }}>
          <Grid container justifyContent={"center"} columnSpacing={3}>
            <Grid item xs={12} sx={{ marginBottom:"10px"}}>
              <Typography component="div" sx={{color: primary, margin: "0", zIndex: 100, fontSize: "5em", lineHeight: "1em", fontWeight: "bold"}}>
                1
              </Typography>
              <Typography variant={"h1"} component="div" sx={{color: primary, margin: "0", zIndex: 100, fontSize: "36px", lineHeight: "40px", fontWeight: "500"}}>
                How are stories sourced?
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography sx={{ margin: "10px 0" }}>
              <b>Open calls for stories</b><br/>
              Regular open calls for stories attract authors from civil society, multi-lateral organizations, governments and academia. The 2023 open call for stories is now closed.
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography sx={{ margin: "10px 0" }}>
              <b>Existing projects or partner projects</b><br/>
              We accept stories on a rolling basis i.e. based on impactful interventions from existing projects or partner projects which demonstrate the integration of SRHR within UHC.
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography sx={{ margin: "10px 0" }}>
              <b>Drawing on the latest evidence</b><br/>
              We re-tailor and re-package peer-review publications into implementation stories.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container justifyContent={"center"} columnSpacing={3}>
        <Grid item xs={12} sm={4} md={3} sx={{textAlign:"center"}}>
          <img src={about2} alt="Selection and development of stories" style={{ width:"100%", maxWidth:"190px" }} className="imgAboutShift"/>
        </Grid>
        <Grid item xs={12} sm={8} md={9} sx={{ margin:"20px 0 30px 0" }}>
          <Grid container justifyContent={"center"} columnSpacing={3}>
            <Grid item xs={12} sx={{ marginBottom:"10px"}}>
              <Typography component="div" sx={{color: primary, margin: "0", zIndex: 100, fontSize: "5em", lineHeight: "1em", fontWeight: "bold"}}>
                2
              </Typography>
              <Typography variant={"h1"} component="div" sx={{color: primary, margin: "0", zIndex: 100, fontSize: "36px", lineHeight: "40px", fontWeight: "500"}}>
              Selection and development of stories
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography sx={{ margin: "10px 0" }}>
              <b>The review process</b><br/>
              Each story is reviewed by technical experts at both WHO & UNFPA. The team draws on the Expert Review Group for this process.
               </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography sx={{ margin: "10px 0" }}>
              <b>Supporting authors</b><br/>
              Stories are not automatically uploaded to the LSP. Once stories are selected, we work closely with authors to finalize stories for publication on the LSP.
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container justifyContent={"center"} columnSpacing={3}>
        <Grid item xs={12} sm={4} md={3} sx={{textAlign:"center"}}>
          <img src={about3} alt="Final approval" style={{ width:"100%", maxWidth:"190px" }} className="imgAboutShift"/>
        </Grid>
        <Grid item xs={12} sm={8} md={9} sx={{ margin:"20px 0 80px 0" }}>
          <Grid container justifyContent={"start"} columnSpacing={3}>
            <Grid item xs={12} sx={{ marginBottom:"10px"}}>
              <Typography component="div" sx={{color: primary, margin: "0", zIndex: 100, fontSize: "5em", lineHeight: "1em", fontWeight: "bold"}}>
                3
              </Typography>
              <Typography variant={"h1"} component="div" sx={{color: primary, margin: "0", zIndex: 100, fontSize: "36px", lineHeight: "40px", fontWeight: "500"}}>
              Final approval
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography sx={{ margin: "010px 0" }}>
              <b>Validation of information</b><br/>
              The evidence of each story is verified at a country level, with support from WHO/ UNFPA country offices and the Ministry of Health before placement on the LSP.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </StyledTabApproach>
  );
};
