import { createTheme } from "@mui/material/styles";

export const primary = "#0F2F4B";

export const theme = createTheme({
    typography: {
        fontFamily: ['Inter', 'Sans-serif'].join(','),
    },
    
    // shape: {
    //     borderRadius: 5,
    // },
    // breakpoints: {
    //     values: {
    //         xs: 0, // after value is phone
    //         sm: 600, // after value is tablet
    //         md: 1280, // after value is low resolution laptop
    //         lg: 1400, // after value is high resolution laptop
    //         xl: 1920,
    //     },
    // },
    components: {
        // MuiOutlinedInput: {
        //     styleOverrides: {
        //         root: {
        //             height: "45px",
        //             borderRadius: "8px",
        //         },
        //     },
        // },
        // MuiInputAdornment: {
        //     styleOverrides: {
        //         positionStart: {
        //             marginTop: "6px",
        //         },
        //     },
        // },
        // MuiTabs: {
        //     styleOverrides: {
        //         indicator: {
        //             backgroundColor: purple,
        //         },
        //     },
        // },
        MuiTypography: {
            styleOverrides: {
                // Heading 2
                h1: {
                    fontStyle: "normal",
                    fontSize: "45px",
                    lineHeight: "50px",
                    fontWeight: "700",
                },
                // Paragraph Bold
                h2: {
                    fontStyle: "normal",
                    fontSize: "18px",
                    lineHeight: "21px",
                },
                // Paragraph Default Regular
                h3: {
                    fontStyle: "normal",
                    fontWeight: "normal",
                    fontSize: "16px",
                },
                // Paragraph Default Medium
                h4: {
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: "16px",
                    lineHeight: "19px",
                },
                // Paragraph Default Bold
                h5: {
                    fontStyle: "normal",
                    fontWeight: "bold",
                    fontSize: "16px",
                    lineHeight: "19px",
                },
                // Paragraph Small Regular
                h6: {
                    fontStyle: "normal",
                    fontWeight: "bold",
                    fontSize: "14px",
                    lineHeight: "17px",
                },
                // Subtitle Bold
                subtitle1: {
                    fontStyle: "normal",
                    fontWeight: "bold",
                    fontSize: "22px",
                    lineHeight: "26px",
                },
                // Subtitle Regular
                subtitle2: {
                    fontStyle: "normal",
                    fontWeight: "normal",
                    fontSize: "22px",
                    lineHeight: "26px",
                },
                // Paragraph Big Regular
                body1: {
                    fontStyle: "normal",
                    fontWeight: "normal",
                    fontSize: "18px",
                    lineHeight: "30px",
                },
                // Paragraph Big Bold
                body2: {
                    fontStyle: "normal",
                    fontSize: "20px",
                    lineHeight: "23px",
                },
                // Heading 1
                caption: {
                    fontStyle: "bold",
                    fontWeight: "700",
                    fontSize: "50px",
                    lineHeight: "50px"
                },
            },
        },
    },
    palette: {
        primary: {
            main: primary,
        },
    },
});
