import React, { FC } from "react";
import { storiesFilterClasses, StyledStoriesFilter } from "./StyledStoriesFilter";
import { Button, FormControl, InputLabel, MenuItem, Paper, Select } from "@mui/material";
import { primary } from "../../common/theme";
import { NavigationRoutes } from "../../routes";
import { useNavigate } from "react-router-dom";

interface IStoriesFilter  {
    countries: string[],
    country: string,
    handleCountryChange: any,
}

export const StoriesFilter: FC<IStoriesFilter> = ({countries, country, handleCountryChange}) => {
    const navigate = useNavigate();

    return <StyledStoriesFilter container className={storiesFilterClasses.root}>
        <Paper sx={{
            width: "500px",
            background: "white",
            padding: "20px",
            height: "50px",
            borderRadius: "20px",
            display: "flex",
            justifyContent: "space-between",
        }}>
            <FormControl>
                <InputLabel id="demo-simple-select-label">Select Country</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={country}
                    label="Country"
                    variant={"standard"}
                    sx={{ width: "150px"}}
                    onChange={handleCountryChange}
                >
                    <MenuItem key={"all"} value={"all"}>All</MenuItem>
                    {countries.map((innerCountry)=>{
                        return <MenuItem key={innerCountry} value={innerCountry}>{innerCountry}</MenuItem>
                    })}
                </Select>
            </FormControl>
            <Button variant={"contained"} sx={{background: primary, borderRadius: "10px"}}
                    onClick={()=>navigate(`/${NavigationRoutes.Stories}`)}>
                Explore now
            </Button>
        </Paper>
        {/*<FormControl className={storiesFilterClasses.selectAlphabet}>*/}
        {/*    <NativeSelect*/}
        {/*        defaultValue={30}*/}
        {/*        inputProps={{*/}
        {/*            name: 'alphabet',*/}
        {/*            id: 'alphabet',*/}
        {/*        }}*/}
        {/*        className={storiesFilterClasses.selectAlphabetInput}*/}
        {/*    >*/}
        {/*        <option value={10}>A-Z</option>*/}
        {/*        <option value={20}>A-Z</option>*/}
        {/*        <option value={30}>A-Z</option>*/}
        {/*    </NativeSelect>*/}
        {/*</FormControl>*/}
    </StyledStoriesFilter>;
};
