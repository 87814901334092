import { Grid, styled } from "@mui/material";

const STORY_PREFIX = "Story";

export const storyClasses = {
  root: `${STORY_PREFIX}-root`,
  sixtySeconds: `${STORY_PREFIX}-sixtySeconds`,
  sixtySecondsCountry: `${STORY_PREFIX}-sixtySecondsCountry`,
  smallBrush: `${STORY_PREFIX}-smallBrush`,
  quote: `${STORY_PREFIX}-quote`,
  sources: `${STORY_PREFIX}-sources`,
  downloadButton: `${STORY_PREFIX}-downloadButton`,
  imageBrush: `${STORY_PREFIX}-imageBrush`,
};

export const StyledStory = styled(Grid)(({ theme }) => ({
  [`&.${storyClasses.root}`]: {
    [`& .${storyClasses.sixtySeconds}`]: {
      paddingTop: "320px",
      marginBottom: "50px",
    },
    [`& .${storyClasses.sixtySecondsCountry}`]: {
      [`& > div`]: {
        position: "relative",
        [`& h2`]: {
          lineHeight: "79px",
          fontWeight: "900",
          marginBottom: "30px",
          position: "relative",
          zIndex: "2",
        },
      },
      [`& .${storyClasses.smallBrush}`]: {
        position: "absolute",
        left: "-15px",
        bottom: "7px",
      },
      [`& p`]: {
        fontSize: "18px",
        lineHeight: "30px",
        fontWeight: "900",
        color: "#011E3F",
        opacity: "0.7",
        maxWidth: "350px",
      },
    },
    [`& .${storyClasses.quote}`]: {
      textAlign: "left",
      fontSize: "18px",
      lineHeight: "26px",
      color: "#011E3F",
      fontFamily: "Inter",
      fontWeight: "100",
      opacity: "1",
    },
    [`& .${storyClasses.downloadButton}`]: {
      fontSize: "16px",
      fontWeight: "normal",
      background: "#011E3F",
      textTransform: "capitalize",
      borderRadius: "10px",
      height: "48px",
      minWidth: "185px",
    },
    [`& .${storyClasses.sources}`]: {
      position: "relative",
      textAlign: "center",
      [`& h5`]: {
        color: "#011E3F",
        fontSize: "18px",
        lineHeight: "24px",
        fontWeight: "normal",
        marginBottom: "25px",
        textAlign: "left",
      },
      [`& p`]: {
        color: "#2C4560",
        opacity: "0.65",
        fontSize: "18px",
        lineHeight: "26px",
        fontWeight: "normal",
        marginBottom: "25px",
        textAlign: "left",
      },
    },
    [`& .${storyClasses.imageBrush}`]: {
      position: "absolute",
      top: "10px",
      right: "-140px",
    },
  },
}));
