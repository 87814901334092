import {Grid, styled} from "@mui/material";

const HEADER_PREFIX = "Navigation";

const classArray = [
    "root",
    "logo",
    "container",
    "navigation",
    "appBarDark",
    "appBarLight",
] as const;

type ArrayElement = typeof classArray[number];

type ClassType = {
    [K in ArrayElement]: string;
}

export const headerClasses = classArray.reduce<ClassType>((combinedClasses, current) => {
    combinedClasses[current] = `${HEADER_PREFIX}-${current}`;
    return combinedClasses;
}, {} as any);

export const StyledHeader= styled(Grid)(({ theme }) => ({
    [`&.${headerClasses.root}`]: {},
    [`& .${headerClasses.appBarDark}`]: {
        background: "#0F2F4B",
        boxShadow: "none",
        position: "relative",
        zIndex: 90,
        [`& .${headerClasses.logo}`]: {
            marginRight: '5px'
        },
        [`& .${headerClasses.container}`]: {
            padding: "15px 24px",
            position: "relative",
            [`&::after`]: {
                position: "absolute",
                content: '""',
                width: "100%",
                height: "1px",
                background: "#fff",
                opacity: "0.15",
                left: "0",
                bottom: "0"
            }
        },
        [`& .${headerClasses.navigation}`]: {
            color: "white",
            marginRight: "20px",
            fontSize: 16,
        },
    },
    [`& .${headerClasses.appBarLight}`]: {
        background: "#AEC3CE",
        boxShadow: "none",
        [`& .${headerClasses.logo}`]: {
            marginRight: '30px'
        },
        [`& .${headerClasses.container}`]: {
            padding: "15px 24px",
            position: "relative",
            [`&::after`]: {
                position: "absolute",
                content: '""',
                width: "100%",
                height: "1px",
                background: "#011E3F",
                opacity: "0.15",
                left: "0",
                bottom: "0"
            }
        },
        [`& .${headerClasses.navigation}`]: {
            color: "#183351",
            marginRight: "20px",
            fontSize: 16,
        },
    },
}));
