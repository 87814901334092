import {Grid, styled} from "@mui/material";
import { primary } from "../../common/theme";

const LATEST_STORIES_PREFIX = "LatestStories";

export const latestStoriesClasses = {
    root: `${LATEST_STORIES_PREFIX}-root`,
    textBrush: `${LATEST_STORIES_PREFIX}-textBrush`,
    card: `${LATEST_STORIES_PREFIX}-card`,
    cardLocation: `${LATEST_STORIES_PREFIX}-cardLocation`,
    cardImg: `${LATEST_STORIES_PREFIX}-cardImg`,
    cardContent: `${LATEST_STORIES_PREFIX}-cardContent`,
    button: `${LATEST_STORIES_PREFIX}-button`,
};

export const StyledLatestStories = styled(Grid)(({ theme }) => ({
    [`&.${latestStoriesClasses.root}`]: {},
    [`& .${latestStoriesClasses.textBrush}`]: {
        position: "absolute",
        zIndex: 1,
        top: "50%",
        left: "40%",
    },
    [`& .${latestStoriesClasses.card}`]: {
        background: "transparent",
        boxShadow: "none",
        position: "relative",
    },
    [`& .${latestStoriesClasses.cardLocation}`]: {
        position: "absolute",
        padding: "10px",
        alignItems: "center",
        [`& img`]: {
            marginRight: "15px"
        },
        [`& h6`]: {
            fontWeight: "normal"
        },
        width: "45%",
        top: 5,
        left: 5,
        background: `${primary}8F`,
        borderRadius: "5px",
        justifyContent: "space-around",
    },
    [`& .${latestStoriesClasses.cardImg}`]: {
        height: "250px",
        borderRadius: "8px",
    },
    [`& .${latestStoriesClasses.cardContent}`]: {
        padding: "5px 0",
        [`& h6`]: {
            fontSize: "18px",
            fontWeight: "normal",
            lineHeight: "24px",
            color: "#011E3F",
            marginBottom: "20px"
        },
    },
    [`& .${latestStoriesClasses.button}`]: {
        fontSize: "16px",
        fontWeight: "normal",
        background: "#011E3F",
        textTransform: "capitalize",
        borderRadius: "10px",
        height: "48px",
        minWidth: "185px"
    },
}));

