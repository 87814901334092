import {Grid, styled} from "@mui/material";

const CAPTION_PREFIX = "Caption";

export const captionClasses = {
    root: `${CAPTION_PREFIX}-root`,
    imgOne: `${CAPTION_PREFIX}-imgOne`,
    imgTwo: `${CAPTION_PREFIX}-imgTwo`,
    imgThree: `${CAPTION_PREFIX}-imgThree`,
    feather: `${CAPTION_PREFIX}-feather`,
    textBrush: `${CAPTION_PREFIX}-textBrush`,
};

export const StyledCaption = styled(Grid)(({ theme }) => ({
    [`&.${captionClasses.root}`]: {
        // background: "radial-gradient(at 10% 100%, #375271 0%, #0F2F4B 34%) 0% 0% no-repeat",
        // color: "#fff",
    },
    [`& .${captionClasses.imgOne}`]: {
        marginBottom: "20px",
        zIndex: 100,
    },
    [`& .${captionClasses.imgTwo}`]: {
        zIndex: 100,
    },
    [`& .${captionClasses.imgThree}`]: {
        marginTop: "100px",
        marginLeft: "20px",
        zIndex: 100,
        position: "relative"
    },
    [`& .${captionClasses.feather}`]: {
        position: "absolute",
        zIndex: 1,
        top: "-30%"
    },
    [`& .${captionClasses.textBrush}`]: {
        position: "absolute",
        zIndex: 1,
        top: "80%",
        left: 0,
    }
}));

