import React, { useState, useEffect } from 'react';
import { Button, Grid, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { primary } from "../common/theme";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { NavigationRoutes } from "../routes";
import { countries, getTopics } from "../Stories/stories-items";

export const StoriesFilter = () => {
    const { country: initialCountry, topic: initialTopic } = useParams();
    
    const navigate = useNavigate();
    const [country, setCountry] = useState(initialCountry || '');
    const [topic, setTopic] = useState(initialTopic || '');
    const [availableTopics, setAvailableTopics] = useState(getTopics(initialCountry));

    useEffect(() => {
        if ((initialCountry) && (countries.includes(initialCountry) || initialCountry === "all")) {
            setCountry(initialCountry);
            setAvailableTopics(getTopics(initialCountry));
        }
    }, [initialCountry]);

    useEffect(() => {
        if (initialTopic && availableTopics.some((t) => t.value === initialTopic)) {
            setTopic(initialTopic);
        } else if (initialCountry === "all") {
            setTopic('all');
        }
    }, [initialTopic, availableTopics]);

    const handleCountryChange = (event: any) => {
        const selectedCountry = event.target.value as string;
        setCountry(selectedCountry);
        setTopic('all');
        setAvailableTopics(getTopics(selectedCountry));
    };

    const handleTopicChange = (event: any) => {
        setTopic(event.target.value as string);
    };

    const handleExploreClick = () => {
        let path = `/${NavigationRoutes.Stories}`;

        if (country) path += `/${country}`;
        else if (topic) path += `/all`;

        if (topic && topic !== "all") path += `/${topic}`;

        navigate(path);
        
        window.scrollTo({
            top: document.getElementById('storiesList')?.offsetTop,
            behavior: 'smooth'
        });
    };

    return (
        <Grid container sx={{
            maxWidth: { lg: "540px" },
            background: "white",
            padding: "10px",
            borderRadius: "20px",
            display: "flex",
            justifyContent: "space-between",
            flexDirection: {lg: "row", xs: "column"},
            gap: "15px",
        }}>
            <Grid item sx={{ display: "flex", gap: "10px", marginBottom: "10px", flexDirection: {lg: "row", xs: "column"} }}>
                <FormControl sx={{ margin: {lg : "5px 0 0 15px", xs: "10px"} }}>
                    <InputLabel className="selectFormControlLabel">Select Country</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={country}
                        label="Country"
                        variant={"standard"}
                        sx={{ width: {lg:"150px"} }}
                        onChange={handleCountryChange}
                    >
                        <MenuItem value="all">All countries</MenuItem>
                        {countries.map((innerCountry)=>{
                            return <MenuItem key={innerCountry} value={innerCountry}>{innerCountry}</MenuItem>
                        })}
                    </Select>
                </FormControl>
                <FormControl sx={{ display: {lg: "block", xs: "none"}, margin: {lg : "5px 0 0 15px", xs: "10px"} }}>
                    <InputLabel className="selectFormControlLabel">Select topic</InputLabel>
                    <Select
                        labelId="topic-select-label"
                        id="topic-select"
                        value={topic}
                        label="Topic"
                        variant={"standard"}
                        sx={{ width: {lg:"150px"} }}
                        onChange={handleTopicChange}
                    >
                        <MenuItem value="all">All topics</MenuItem>
                        {availableTopics.map((innerTopic) => (
                            <MenuItem key={innerTopic.value} value={innerTopic.value}>
                                {innerTopic.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            
            <Button variant={"contained"} sx={{background: primary, borderRadius: "10px", margin: {xs: "0 auto", lg: "0"}, padding: "20px 18px"}} onClick={handleExploreClick}>
                View stories
            </Button>
        </Grid>
    );
};