import {Grid, styled} from "@mui/material";

const prefix = "Resources";

export const resourcesClasses = {
    root: `${prefix}-root`,
    innerRoot: `${prefix}-innerRoot`,
    textBrush: `${prefix}-textBrush`,
    card: `${prefix}-card`,
    cardLocation: `${prefix}-cardLocation`,
    cardImg: `${prefix}-cardImg`,
    cardContent: `${prefix}-cardContent`,
    leftCol: `${prefix}-leftCol`,
    title: `${prefix}-title`,
    titleWrapper: `${prefix}-titleWrapper`,
    smallBrush: `${prefix}-smallBrush`,
    description: `${prefix}-description`,
    nextStory: `${prefix}-nextStory`,
    rightCol: `${prefix}-rightCol`,
    brush: `${prefix}-brush`,
    button: `${prefix}-button`,
};

export const StyledResources = styled(Grid)(({ theme }) => ({
    [`&.${resourcesClasses.root}`]: {},
    [`& .${resourcesClasses.textBrush}`]: {
        position: "absolute",
        zIndex: 1,
        top: "90%",
        left: "40%",
    },
    [`& .${resourcesClasses.card}`]: {
        background: "transparent",
        boxShadow: "none",
        position: "relative",
    },
    [`& .${resourcesClasses.cardLocation}`]: {
        position: "absolute",
        width: "100%",
        top: "0",
        padding: "10px",
        alignItems: "center",
        [`& img`]: {
            marginRight: "15px"
        },
        [`& h6`]: {
            fontWeight: "normal"
        },
    },
    [`& .${resourcesClasses.cardImg}`]: {
        height: "250px",
        borderRadius: "8px",
        objectFit: "contain",
    },
    [`& .${resourcesClasses.cardContent}`]: {
        padding: "5px 0",
        height: "220px",
        display: "flex",
        alignItems: "",
        [`& h6`]: {
            fontSize: "18px",
            fontWeight: "normal",
            lineHeight: "24px",
            color: "#011E3F",
            marginBottom: "20px"
        },
    },
    [`& .${resourcesClasses.innerRoot}`]: {
        background: "radial-gradient(at 10% 100%, #375271 0%, #0F2F4B 34%) 0% 0% no-repeat",
        color: "#fff",
        paddingBottom: "50px"
    },
    [`& .${resourcesClasses.leftCol}`]: {
        paddingTop: "150px"
    },
    [`& .${resourcesClasses.titleWrapper}`]: {
        position: "relative"
    },
    [`& .${resourcesClasses.title}`]: {
        fontSize: "80px",
        fontWeight: "normal",
        lineHeight: "normal",
        padding: "0 0 30px 30px",
        position: "relative",
        zIndex: 2
    },
    [`& .${resourcesClasses.smallBrush}`]: {
        position: "absolute",
        left: "-5px",
        bottom: "35px",
        zIndex: 1
    },
    [`& .${resourcesClasses.description}`]: {
        fontSize: "24px",
        lineHeight: "24px",
        opacity: "0.8",
        marginBottom: "70px"
    },
    [`& .${resourcesClasses.nextStory}`]: {
        justifyContent: "end",
        alignItems: "center",
        [`& h5, h6`]: {
            fontSize: "16px",
            fontWeight: "normal",
            lineHeight: "normal"
        },
        [`& h6`]: {
            opacity: "0.8",
            marginLeft: "25px"
        },
        [`& svg`]: {
            width: "16px",
            color: "#E38A15",
            marginLeft: "40px"
        },
    },
    [`& .${resourcesClasses.rightCol}`]: {
        position: "relative",
        paddingTop: "85px",
        alignItems: "baseline",
        justifyContent: "center"
    },
    [`& .${resourcesClasses.brush}`]: {
        position: "absolute",
        top: "-35px",
        left: "-20px",
        zIndex: 91
    },
    [`& .${resourcesClasses.card}`]: {
        background: "transparent",
        color: "#fff",
        boxShadow: "none",
        position: "relative",
        zIndex: 92
    },
    [`& .${resourcesClasses.cardLocation}`]: {
        position: "absolute",
        width: "100%",
        top: "0",
        padding: "10px",
        alignItems: "center",
        [`& img`]: {
            marginRight: "15px"
        },
        [`& h6`]: {
            fontWeight: "normal"
        },
    },
    [`& .${resourcesClasses.cardContent}`]: {
        padding: "5px 0",
        [`& h6`]: {
            fontSize: "18px",
            fontWeight: "normal",
            lineHeight: "24px"
        },
    },
    [`& .${resourcesClasses.button}`]: {
        fontSize: "16px",
        fontWeight: "normal",
        background: "#011E3F",
        textTransform: "capitalize",
        borderRadius: "10px",
        height: "48px",
        minWidth: "185px"
    },
}));

