import {Container, Grid, Typography} from "@mui/material";

export const BlueBanner = () => {
    const barContainerStyle = {
        backgroundColor: '#009ADE',
        textAlign: 'center',
    }

    return <Grid item sx={barContainerStyle}>
        <Container maxWidth={"xl"}>
            <Grid container sx={{padding: "15px 0", position: "relative" }}>
                <Grid item lg={12}>
                    <Grid container sx={{position: "relative", padding:"0", justifyContent: "center" }}>
                        <Typography variant={"h3"} sx={{color: "#FFF", fontWeight:"bold", margin: "0", textAlign: "center", fontSize: "20px", maxWidth: "990px"}}>
                        Please join us for a webinar on Strengthening Integration of Sexual and Reproductive Health (SRH) in National Universal Health Coverage (UHC) Plans and Processes in Eastern Europe and Central Asia on 10 December at 11:00 CET. Register here: <a href="https://bit.ly/4i3nsZt" target="_blank" style={{color: "#FFF"}} rel="noreferrer">https://bit.ly/4i3nsZt</a>
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    </Grid>
};