import React, { useEffect, useState } from "react";
import { adminClasses as classes, StyledAdmin } from "./StyledAdmin";
import { Home } from "../Home/Home";
import { useNavigate, useParams } from "react-router-dom";
import { countries, stories } from "../Stories/stories-items";
import { IStory } from "../Stories/interfaces/IStory";
import { Button, Container, Grid } from "@mui/material";
import { StoriesFilter } from "../Stories/StoriesFilter/StoriesFilter";
import { StoriesList } from "../Stories/StoriesList/StoriesList";
import { NavigationRoutes } from "../routes";
import { useGA } from "../common/hooks";

export const Admin = () => {
    useGA("Admin");
    const hasToken = localStorage.getItem("token");
    const { country } = useParams();
    const [innerCountry, setInnerCountry] = useState(countries[0]);
    const [innerStories, setInnerStories] = useState(stories);
    const navigate = useNavigate();

    useEffect(()=> {
        if(country) {
            setInnerStories(stories.filter((story: IStory) => story.country === country));
            setInnerCountry(country)
        }
    }, [ country]);

    const handleCountryChange = (event: any) => {
        const value = event.target.value;
        setInnerCountry(value);
        if(value === "all") {
            setInnerStories(stories);
        } else {
            setInnerStories(stories.filter((story: IStory) => story.country === event.target.value));
        }
    };

    const logout = ()=> {
        localStorage.setItem("token", "");
        navigate(`/${NavigationRoutes.Login}`);
    }

    if(hasToken) {
        return <StyledAdmin className={classes.root}>
            <Container maxWidth={"lg"}>
                <Grid container justifyContent={"flex-end"} sx={{ marginTop: "20px" }}>
                    <Button variant="outlined" onClick={()=>logout()}>Logout</Button>
                </Grid>
            </Container>
            <Container maxWidth={"lg"}>
                <StoriesFilter countries={countries} country={innerCountry} handleCountryChange={handleCountryChange} />
                <StoriesList stories={innerStories} />
            </Container>
        </StyledAdmin>;
    }
    return <Home />
};
