import {Grid, styled} from "@mui/material";

const prefix = "Login";

export const loginClasses = {
    root: `${prefix}-root`,
};

export const StyledLogin = styled(Grid)(({ theme }) => ({
    [`&.${loginClasses.root}`]: {},
}));

