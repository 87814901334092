import {Grid, styled} from "@mui/material";

const STORY_ACCORDION_PREFIX = "StoryAccordion";

export const storyAccordionClasses = {
    root: `${STORY_ACCORDION_PREFIX}-root`,
    accordion: `${STORY_ACCORDION_PREFIX}-accordion`,
    accordionSummary: `${STORY_ACCORDION_PREFIX}-accordionSummary`,
};

export const StyledStoryAccordion = styled(Grid)(({ theme }) => ({
    [`&.${storyAccordionClasses.root}`]: {
        marginBottom: "65px"
    },
    [`& .${storyAccordionClasses.accordion}`]: {
        boxShadow: "none",
        color: "#011E3F",
        [`& svg`]: {
            border: "1px solid #E38A15",
            borderRadius: "10px",
            color: "#2C4560",
        },
    },
    [`& .${storyAccordionClasses.accordionSummary}`]: {
        [`& p`]: {
            fontSize: "24px",
            lineHeight: "32px",
            fontWeight: "normal"
        }
    },
}));
