import {Grid, styled} from "@mui/material";

const FEATURE_STORY_PREFIX = "FeatureStory";

export const featureStoryClasses = {
    root: `${FEATURE_STORY_PREFIX}-root`,
    textBrush: `${FEATURE_STORY_PREFIX}-textBrush`,
    feather: `${FEATURE_STORY_PREFIX}-feather`,
};

export const StyledFeatureStory = styled(Grid)(({ theme }) => ({
    [`&.${featureStoryClasses.root}`]: {},
    [`& .${featureStoryClasses.textBrush}`]: {
        position: "absolute",
        zIndex: 1,
        top: "50%",
        left: "40%",
    },
    [`& .${featureStoryClasses.feather}`]: {
        position: "absolute",
        zIndex: 1,
        top: "25px",
        left: "-60px"
    },
}));

