export enum NavigationRoutes {
    Default = '/',
    Home = '/',
    About = 'about',
    Stories = 'stories',
    VideoTelling  = 'video-telling',
    Story = 'story',
    Resources = 'resources',
    Links = 'home#links',
    Login = "login",
    Admin = "admin"
}

export const links: NavigationRoutes[] = [
    NavigationRoutes.Home,
    NavigationRoutes.About,
    NavigationRoutes.Stories,
    NavigationRoutes.VideoTelling,
    NavigationRoutes.Resources
];
export const pages = ['Home', 'About', 'Implementation Stories', 'Video Telling', 'Resources' ];

export const customNavigate = (navigate: any, route: NavigationRoutes) => {
    navigate(`/${route.replaceAll("/", "")}`);
    window.scrollTo(0, 0);
}
