import React from 'react';
import {Header} from "../Header/Header";
import {Footer} from "../Footer/Footer";
import {StyledViewContainer, viewContainerClasses} from "./StyledViewContainer";
import {Mode} from "../interfaces/mode";

interface ViewContainerProps {
    mode: Mode;
    children: React.ReactNode;
}

export const ViewContainer = (props: ViewContainerProps) => {
    return <StyledViewContainer className={viewContainerClasses.root}>
        <Header mode={props.mode}/>
        {props.children}
        <Footer/>
    </StyledViewContainer>;
};
